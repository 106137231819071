// i18next-extract-mark-ns-start products-valdyn-cbd

import { VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Chain dynamics</h2>
						<p>
							VALDYN can be used to model most chain types including
							roller and inverted tooth chains.
						</p>
						<h2>Belt dynamics</h2>
						<p>
							VALDYN can model timing belts and auxiliary drive belts.
							Timing belts with non-circular pulleys can be modelled,
							as can ‘wet’ belts using guides.
						</p>
						<p>
							Creating chain models is fast and simple with minimal
							data input and automated wrapping of the chain around
							the sprockets. Sprocket tooth, link and guide shapes can
							be built from lines, arcs and involutes or defined by
							B-splines, which can be fitted to measured data.
						</p>
						<p>
							The behaviour of the chain can be viewed as an animation
							in the time domain and as mode shapes in the frequency
							domain. Comprehensive plotted outputs of force, friction
							and power loss is available. The time variation of
							forces on individual components or at given points in
							the chain run and spatial variation of forces around the
							chain run can also be plotted.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Simple model generation</li>
							<li>Class-leading simulation speed</li>
							<li>Full capability for modelling tensioner systems</li>
							<li>
								Interaction of belt with camshaft and crankshaft
							</li>
							<li>
								Span flap and system modes by perturbation at any
								time step
							</li>
							<li>
								Automatic model simplification for linear frequency
								domain analysis
							</li>
							<li>Results animation</li>
							<li>Comprehensive plotted output</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn-cbd", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

